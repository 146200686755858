@import "../system/icon";

header{
	background: $headerTopBgColor;
	@extend .animated;
	padding: 0.5em 0;
	&.scrolled{
		@extend .animated;
		@extend .fadeInDown;
		position: fixed;
		z-index: 100;
		width: 100%;
		top: 0;
		padding: 0;
		@include media(lgs) {
			display: none;
		}
		.wow{
			visibility: visible !important;
		}
		.header-bottom{
			padding: 0.5em 0;
			.header-contacts{
				padding: 0;
				width: 45%;
			}
			.logo{
				width: 55%;
				.logo-img{
					width: 130px;
					height: 142px;
				}
				&:before{
					left: 160px;
				}
				.slogan{
					font-size: 16px;
					padding: 0.4em 0 0 1em;
				}
			}
			.phones-list__item{
				> a{
					line-height: 30px;
				}
			}
			.mail{
				.mail-wrapper{
					margin: 0 0 .5em;
				}
			}
			.times-block{
				.title{
				}
			}
			.header-cart{

				.tofavorite_button{
					padding: 0 5px 0 0;
					top: -10px;
					.count{
						margin: 5px 0 0;
					}
				}
				.cart{
					> .header-cart__
					title{
						display: none;
					}
					&:before{
						font-size: 25px;
					}
				}
			}
			.call-order{
				&.button{
					font-size: 12px;
					padding: 5px 14px;
				}
			}
		}
		nav{
			background: #025185;
			margin: 0;
			.menu_level_1{
				padding: 0 0 0 11em;
				> li{
					> a{
						padding: 0.5em;
					}
				}
			}
			.search{
				margin: 0;
				.search-form{
					margin: 4px 0 0;
				}
			}
		}
	}
}
.header-bottom {
	.logo {
		float: $logoPos;
		width: $logoWidth;
		margin: $logoMargin;
		padding: $logoPadding;
		min-height: 80px;
		&:before{
			content: '';
			position: absolute;
			left: 210px;
			top: 6px;
			width: 465px;
			height: 65px;
			background: url(/data/logo/slogan.png) 0 center no-repeat;
			@include media(lgs) {
				left: 0;
				right: 0;
				margin: 0 auto;
			}
			@include media(md) {
				top: 5px;
				width: 265px;
				background-size: contain;
			}
			@include media(xmd) {
				left: inherit;
				right: 1em;
				background: url(/data/logo/slogan.png) 0 0 no-repeat;
				background-size: contain;
			}
			@include media(xs) {
				top: 85px;
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}
		// @extend .clearfix;
		a{
			display: block;
			@extend .clearfix;
		}
		.logo-img{
			position: absolute;
			left: 0;
			float: left;
			// width: $logoImgwidth;
			width: $logoImgmaxWidth;
			// height: $logoImgheight;
			height: $logoImgmaxHeight;
			vertical-align: middle;
			z-index: 2;
			@include media(lgs) {
				position: relative;
				width: 100px;
				height: 109px;
			}
			@include media(md) {
				width: 70px;
				height: 76px;
			}
			@include media(xs) {
				float: none;
			}
		}
		.slogan{
			font-size: 20px;
			font-weight: 900;
			float: left;
			color: $textColor;
			text-align: center;
			padding: 0 0.5em;
			text-transform: uppercase;
		}
	}
	.header-contacts {
		float: $heaContFloat;
		padding: $heaContPadding;
		width: $heaContWidth;
		@extend .clearfix;
	}
	&.w_slogan{
		.logo{
			width: $logoWidth + 10%;
			padding: 0.5em 0 0;
			.logo-img{
				max-width: $logoImgmaxWidth - 50px;
				max-height: $logoImgmaxHeight;
			}
			.slogan{
				width: calc(100% - #{$logoImgmaxWidth} + 50px);
			}
		}
		.header-contacts {
			width: $heaContWidth - 10%;
			padding: 0;
		}
	}
	.phones {
		float: left;
		padding: 0 1em 0 2em;
		width: 35%;
	}
	.mail {
		float: left;
		padding: 0 1em;
		// width: 25%;
		.mail-wrapper {
			padding: 0 2em;
			@include media(sm) {
				padding: 0 1em 0 2em;
			}
		}
	}
	.times-block {
		float: left;
		padding: 0 1em;
		width: 25%;
	}
	.header-cart {
		position: relative;
		float: right;
		padding: 0 1em;
		width: 25%;
	}
	.auth-block {
		.auth-box {
			float: left;
			padding: 10px 1em 0;
			.reg {
				margin-right: 10px;
			}
			.enter {
			}
		}
	}
	@include media(lgs) {
		.logo {
			text-align: center;
			width: 100%;
		}
		.header-contacts {
			width: 100%;
			padding: 0 1em;
			.phones,
			.mail,
			.times-block,
			.header-cart{
				width: 50%;
				margin: 0;
				min-height: 73px;
			}
			.mail,
			.times-block,
			.header-cart{
				padding: 0;
			}
		}
	}
	@include media(xmd) {
		.logo {
			float: none;
			& > a {
				display: block;
			}
			.slogan{
				display: inline-block;
				vertical-align: middle;
				float: none;
			}
		}
		.header-contacts {
			.phones,
			.mail,
			.times-block,
			.header-cart{
				width: 40%;
			}
			.mail,
			.times-block,
			.header-cart{
				width: 60%;
			}
		}
	}
	@include media(md) {

	}
	@include media(xs) {
		.header-contacts {
			padding: 50px 1em 0;
			.phones,
			.mail,
			.times-block,
			.header-cart{
				min-height: auto;
				width: 100%;
			}
			.mail,
			.times-block,
			.header-cart{
				padding: 0 0 1em;
			}
		}
	}
}
/* конец раскладки */
.header-bottom {
	& > .container {
		position: relative;
	}
	.phones {
		position: relative;
		.title {
			font-size: 15px;
			margin: 0 0 3px;
			text-transform: uppercase;
		}
		.icon {
			@include fontawesome('\f095') {
				color: $iconColor;
			}
		}
	}
	.phones-list {
		display: inline-block;
		list-style: none;
		margin: 0;
		position: relative;
		vertical-align: middle;
	}
	.phones-list__item {
		& > a {
			color: $white;
			display: block;
			font-size: 16px;
			font-weight: bold;
			line-height: 36px;
			text-decoration: none;
			white-space: nowrap;
		}
		.pref {
			font-weight: normal;
		}
	}
	.mail {
		display: flex;
		align-items: center;
		.mail-wrapper {
			position: relative;
			// margin: 0 0 1em;
			.icon {
				@include fontawesome('\f0e0') {
					color: $iconColor;
				}
			}
			@include media(lg) {
				margin: 0 0 0.5em;
			}
		}
	}
	.mail-link {
		display: block;
		font-size: 15px;
		line-height: 36px;
		vertical-align: middle;
		white-space: nowrap;
		color: $white;
		&:hover{
			color: $linkColor;
		}
	}
	.call-order {
		position: relative;
		width: 50px;
		height: 50px;
		text-align: center;
		white-space: nowrap;
		border-radius: 50%;
		@include fontawesome('\f095') {
			font-size: 30px;
			color: $white;
			margin: -20px 0 0 -12px;
		}
		span{
			display: none;
		}
		&.button{
			border-bottom: 3px solid $linkColor - 10%;
			&:hover{
				border-color: $linkColor - 30%;
			}
			&:active {
				border-color: $linkColor;
			}
		}
	}
	.times-block {
		.title {
			font-size: 15px;
			margin: 0 0 3px;
			text-transform: uppercase;
			@include media(md) {
				padding: 0 0 0 2em;
			}
		}
	}
	.times {
		display: block;
		padding: 0 0 0 1.5em;
		position: relative;
		white-space: nowrap;
		@include media(md) {
			padding: 0 0 0 2em;
		}
		& > span {
			font-weight: 900;
		}
		@include fontawesome('\f017') {
			color: $iconColor;
			left: 0;
			margin: -12px 0 0 0;
			@include media(md) {
				margin: -25px 0 0;
			}
		}
	}
}
.header-cart {
	cursor: default;
	padding: 0 0 5px;
	text-align: right;
	user-select: none;
	&.open {
		.cart {
			&.full {
				&:before {
					color: $iconColor;
				}
			}
		}
	}
	.cart {
		display: inline-block;
		position: relative;
		text-align: left;
		white-space: nowrap;
		width: 100%;
		@include fontawesome('\f07a') {
			color: $iconColor;
			font-size: 34px;
			margin: -19px 0 0;
			left: 0;
		}
		& > .header-cart__title-mob {
			display: none;
		}
		& > .header-cart__title {
			font-size: 15px;
			text-transform: uppercase;
		}
		.summ-block {
			padding: 0 0 0 3em;
		}
		& > .count {
			padding: 0 0 0 3em;
			& > .count-text {
				display: inline-block;
			}
			& > .count-quantity {
				display: inline-block;
			}
		}
	}
}
.desktop {
	.header-cart {
		.cart {
			&.full {
				cursor: pointer;
			}
			&:not(.empty) {
				&:hover {
					&:before {
						color: $linkColor;
					}
				}
			}
		}
	}
}
#progressBar{
	height: 3px;
	background: $linkColor;
	top: 0;
	left: 0;
	right: auto;
	bottom: auto;
	position: fixed;
	z-index: 9999;
	pointer-events: none;
}
.tofavorite_button{
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.5em 1em;
	&:hover{
		cursor: pointer;
	}
	@include fontawesome('\f004') {
		font-size: 20px;
		margin: -5px 0 0 -20px;
		color: $iconColor;
	}
	.count{
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
		line-height: 20px;
		border-radius: 50%;
		text-align: center;
		color: $white;
		background: $linkColor;
		z-index: 2;
	}
}