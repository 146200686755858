.profile-wrapper {
  background: #fff;
  min-width: 260px;
  max-width: 292px;
  padding: 1em;
> div{
    min-width: 260px;
    max-width: 292px;
    &.skidka-block{
      max-width: 100%;
      width: 100%;
      .current_discount{

      }
      .discount_depends{
        padding: 1em 0 3em;
        font-size: 18px;
      }
      .discount_amount{
        height: 100%;
        font-size: 0;
        height: 16px;
        display: flex;
        background: #ddd;
        @include media($md){
          font-size: inherit;
          display: block;
          height: auto;
          background: none;
        }
        &_percent{
          display: inline-block;
          width: 100%;
          height: 100%;
          border-right: 1px solid #fff;
          z-index: 2;
          position: relative;
          vertical-align: top;
          @extend .clearfix;
          @include media($md){
            display: block;
            margin: 1em 0;
            background: #ddd;
            text-align: center;
            padding: 0 1em;
          }
          b,
          span{
            display: block;
            position: absolute;
            top: -25px;
            left: 0;
            text-align: center;
            font-size: 16px;
            width: 100%;
            @include media($md){
              float: left;
              display: inline-block;
              top: 0;
              position: relative;
              width: auto;
            }
          }
          span{
            top: inherit;
            font-size: 14px;
            bottom: -25px;
            @include media($md){
              float: right;
              bottom: 0;
            }
          }
          &.active{
            background: $linkColor--hover;
          }
        }
      }
      .current_discount_amount{
        font-size: 16px;
        padding: 3em 0 0;
        b{
          @include media($md){
            display: block;
          }
        }
      }
    }
  }
  .reg_col_1,
  .reg_col_2 {
    display: block;
  }

  .reg_col_1 {
    color: $textColor;
    font-size: 12px;
    padding: 0 0 0.25em;
  }

  .reg_col_2 {
    padding: 0 0 0.25em;
    position: relative;

    &.last {
      padding: 0;
      margin: 1em 0 0;
    }

    .input-text {
      width: 100%;
    }
  }


}



.changepass-block,
.profile-block {

  & > form {
    & > table {
      table-layout: fixed;
      width: 100%;
    }
  }
}

.changepass-block {
  .changepass-block__title {
    margin: 1em 0 0.5em;
    text-align: center;
  }
}


@include media(sm) {

  .profile-block {
    margin: 1em 0 0;


    .profile-table {
      width: 100%;

      tr {
        display: block;

        &:last-child {
          .reg_col_1 {
            line-height: 0;
          }
        }
      }
    }

    .input-text {
      width: 100%;
    }

    .reg_col_1,
    .reg_col_2 {
      display: block;
    }
  }

  .changepass-block {

  }

  .pass-change-table {
    table-layout: fixed;
    width: 100%;

    tr {
      display: block;

      &:last-child {
        .reg_col_1 {
          line-height: 0;
        }
      }
    }

    .input-text {
      width: 100%;
    }

    .reg_col_1,
    .reg_col_2 {
      display: block;
    }
  }

  .reg_col_1 {
    font-size: 14px;
  }

}


.orders_btnm {
  margin: 1em 0 2em;
}

