.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;

  @extend .clearfix;
}


main {
  width: 100%;
  > .container{
  }
  &.market{
    .module-inner{
      &.new{
        border-top: none;
      }
    }
  }
}

aside {
  float: left;
  padding: 0 1em;
  width: 270px;
  overflow: hidden;

  @include media(md) {
    float: none;
    width: 100%;
  }
}

.content-block {
  float: right;
  padding: 0 1em;
  width: 100%;
  min-height:calc(100vh - 94px - 49px - 433px);

  @include media(md) {
    float: none;
    width: 100%;
  }
}

.inner{
  .content-block {
   padding: 5em 1em 0;
 }
}

section{
  margin: 1em 0 0;
  padding: 0 1em;
  &:not(#landing-list){
    background: #eaeaea;
  }
  &#landing-list{
    padding: 0;
  }
  &#yamap{
    margin: 1em 0 0;
    padding: 0;
    background: none;
    .module-inner{
      margin: 0;
    }
  }
  .feedback{
    background: $white;
    border: 1px solid $borderColor;
  }
}

.mobile,
.tablet{
  section{
    padding: 0;
  }
}

html{
  &.section{
    .content-block{
      width: 100%;
    }
  }
}