.reg_reg-section {
  margin: 2em 0 0;

  table {
    table-layout: fixed;
    max-width: 350px;
    width: 100%
  }

  .input-text {
    width: 100%;
  }

  .reg_col_1 {
    font-size: 13px;
    padding: 0 1em 0 0;
    // width: 138px;
  }

  .reg_col_2 {
    padding: 0 0 0.5em;
    position: relative;
  }

  .button {
    margin: 1em 0 0;
  }

  .last {
    text-align: center;

    .button {
      display: inline-block;
    }
  }
}