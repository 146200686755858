#landing-list{
  .module-header{
    max-width: calc(#{$containerWidth} - 2em);
    margin: 0 auto 1em;
  }
}

.landing-list{
  &__item{
    margin: 0 0 3em;
    padding: 3em 0;
    background: $footerTopBgColor;
    &-title{
      padding: 0 0 1em;
      span{
        display: block;
        font-size: 20px;
        font-weight: 700;
      }
    }
    &-image{
      background: $white;
      padding: 1em;
      border: 1px solid $borderColor;
      img{
        @extend .img-responsive;
      }
    }
    &-link{
      text-align: right;
    }
  }
}