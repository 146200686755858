/* Стили карточки в каталоге */
html{
  &.section{
    .catalog-item {
      border: 1px solid $borderColor;
    }
  }
}
.catalog-item {
  padding: 0.25em 1em 5em 1em;
  position: relative;
  border-radius: $borderRadius;
  overflow: hidden;
  &:hover{
    .product_photo {
      .show_product{
        .photo_hover{
          opacity: 1;
          @extend .animated;
          @extend .flipInY;
        }
      }
    }
  }
  .fav-link {
    position: absolute;
    top: 0;
    right: 0;
    &.added{
      &:before{
        color: $linkColor;
      }
    }
    &:before {
      color: $grey;
      font-size: 15px;
      margin: -11px 0 0 -7px;
    }

    &:hover {
      &:before {
        color: $linkColor;
      }
    }
  }

  .product_photo {

    & > .show_product {
      display: block;
      position: relative;

      & > img {
        display: block;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;
      }
      .photo_hover{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }


  .catalog-item__title {
    padding: 0.5em 0;
    text-align: center;

    & > .show_product {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .short_description {
    p{
      padding: 0 0 0.5em;
    }
    ul{
      list-style: none;
      padding: 0;
    }
  }


  .catalog-item__bottom {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em;
  }


  .catalog-item__price-block {
    float: left;
  }


  .prod_price_old {
    line-height: 1;

    & > span {
      color: #8f8f8f;
      font-size: 17px;
      text-decoration: line-through;
    }
  }

  .prod_big_price {
    line-height: 1.333;

    & > span {
      color: #000;
      font-size: 24px;
      @include media(xlg){
        font-size: 16px;
      }

      &.currency {
        font-size: 19px;
        @include media(xlg){
          font-size: 14px;
        }
      }
    }
  }
  .kolvo {
    background: $white;
    float: none;
    margin: 0;
    padding: 3px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    visibility: hidden;
    width: auto;
    z-index: 1;
    cursor: default;
    border-radius: $borderRadius;
  }
  .kolvo_buy_button_add {
    float: right;
    position: relative;
    & > .btn_add {
      display: inline-block;
      position: relative;
      z-index: 2;
    }
  }


  @include media(xlg) {
    .catalog-item__title {
      & > .show_product {
        font-size: 18px;
      }
    }
    .short_description {
      * {
        font-size: 14px;
      }
    }
  }
  .product-labels{
    top: 3em;
    right: 1.5em;
  }
}
.desktop {
  .catalog-item {
    .kolvo {
      transition: all 0.3s ease;
      transform: translate3d(0, 0, 0);
    }
    .kolvo_buy_button_add {
      &.hover {
        &:hover {
          &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 10%;
            height: 100%;
            margin: 0 0 0 -10px;
          }
          .kolvo {
            transform: translate3d(-110%, 0, 0);
            transition: transform 0.3s ease;
            visibility: visible;
            border: 1px solid $linkColor--hover;
          }
        }
      }
    }
  }
}

.flexbox {
  .catalog-item {
    &:before,
    &:after {
      display: none;
    }
  }
  .catalog-item__bottom {
    display: flex;
    justify-content: space-between;
    .kolvo_buy_button_add {
      float: none;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .catalog-item__price-block {
      float: none;
    }
  }
  .recommended-products,
  .last-viewed-products{
    .catalog-item__bottom{
    }
  }
  .catalog-list {
    &._4{
      & > .catalog-item {
        padding: 0.25em 1em 7em 1em;
        .catalog-item__bottom{
          display: block;
          text-align: center;
          bottom: 0;
          .catalog-item__price-block{

          }
          .kolvo_buy_button_add{
            display: block;
            > .btn_add{
              float: none;
            }
          }
        }
      }
    }
  }
}


.catalog-description__top {
  margin: 0 0 2em;
}

.catalog-description__bottom {
  margin: 0 0 2em;
}

/* Раскладка карточек в каталоге */
.catalog-list {
  margin: 1em 0 0;
  @extend .clearfix;
  & > .catalog-item {
   float: left;
   margin: 0 2em 2em 0;
 }
 &._3{
   & > .catalog-item {
    width: calc(33.333% - 1.34em);
    &:nth-child(3n + 3) {
      margin: 0 0 2em;
    }
    @include media(lg) {
      width: calc(50% - 1em);
      &:nth-child(3n + 3) {
        margin: 0 2em 2em 0;
      }
      &:nth-child(2n + 2) {
        margin: 0 0 2em;
      }
    }
    @include media(sm) {
     margin: 0 0 2em;
     width: 100%;
   }
 }
}
&._4{
 & > .catalog-item {
  width: 100%;
  @media only screen and (min-width: $lg){
   width: calc(25% - 0.75em);
   margin: 0 1em 2em 0;
 }
 &:nth-child(4n + 4) {
   @media only screen and (min-width: $lg){
    margin: 0 0 2em;
  }
}
@media only screen and (min-width: $md) and (max-width: $lg - 1){
  width: calc(33.333% - 0.67em);
  margin: 0 1em 2em 0;
}
&:nth-child(3n + 3) {
  @media only screen and (min-width: $md) and (max-width: $lg - 1){
    margin: 0 0 2em 0;
  }
}
@media only screen and (min-width: $xs) and (max-width: $md - 1){
  width: calc(50% - 0.67em);
  margin: 0 1em 2em 0;
}
&:nth-child(2n + 2) {
  @media only screen and (min-width: $xs) and (max-width: $md - 1){
    margin: 0 0 2em 0;
  }
}
}
}
}


.sort_panel {
  font-style: normal;

  & > span {
    margin: 0 0.5em 0 0;
    position: relative;

    & > a {
      margin: 0 3px 0 0;
    }

    &.this_sort {
      font-weight: bold;
    }

    &.desc_sort {
      &:after {
        content: "\2193";
      }
    }

    &.asc_sort {

      &:after {
        content: "\2191";
      }
    }

    &:first-child {
      margin: 0 0.5em;
    }
  }

  @include media(md) {
    font-size: 14px;
  }

  @include media(sm) {
    font-size: 12px;
  }

}

section{
  .catalog-item{
    background: $white;
    border: 1px solid $borderColor;
  }
}
